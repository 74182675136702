import registerFontAwesomeIcons from './registerIcons';
import styles from './Wrapper.scss';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import 'fontsource-fira-sans/200.css';
import 'fontsource-fira-sans/400.css';
import 'fontsource-fira-sans/600.css';
import 'fontsource-fira-sans/700.css';

registerFontAwesomeIcons();

const Wrapper = ({ children, noBackground, className }) => {
  return (
    <div
      className={classNames(
        {
          [styles.container]: !noBackground,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  noBackground: PropTypes.bool,
};

Wrapper.defaultProps = {
  noBackground: false,
};

export default Wrapper;
