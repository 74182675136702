import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faCircle,
  faFileDownload,
  faEnvelope,
  faEnvelopeOpenText,
  faHandsHeart,
  faInfo,
  faPhone,
  faChevronLeft,
  faQuestion,
  faShoePrints,
  faNewspaper,
  faCar,
  faRocket,
  faPlaneDeparture,
  faHome,
  faUser,
  faShippingFast,
} from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import brands from '@wienenergiegithub/ui-next/src/common/assets/icons/brands';
import solid from '@wienenergiegithub/ui-next/src/common/assets/icons/solid';
import regular from '@wienenergiegithub/ui-next/src/common/assets/icons/regular';

const icons = [
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircle,
  faFileDownload,
  faEnvelope,
  faEnvelopeOpenText,
  faHandsHeart,
  faInfo,
  faPhone,
  faQuestion,
  faNewspaper,
  faShoePrints,
  faCar,
  faPlaneDeparture,
  faRocket,
  faHome,
  faUser,
  faShippingFast,
];

export default function registerFontAwesomeIcons() {
  library.add(...icons);
  library.add(...brands);
  library.add(...regular);
  library.add(...solid);
}
